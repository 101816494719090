import {
  AlertOutlined,
  ClockCircleOutlined,
  CloudOutlined,
  CoffeeOutlined,
  CreditCardOutlined,
  PieChartOutlined,
  RestOutlined,
  SettingOutlined,
  ShopOutlined,
  TabletOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import Text from "antd/lib/typography/Text";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { media, style } from "typestyle";
import { useTypedMutation } from "../graphql/hooks";
import { useWindowSize } from "../hooks/useWindowSize";
import HeaderBar from "./HeaderBar";
import { DarkModeToggle } from "./ui/DarkModeToggle";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

export default function DashLayout({ children, ...props }) {
  const { width } = useWindowSize();
  const [collapsed, setCollapsed] = useState(global.window?.innerWidth < 1024);

  const router = useRouter();
  const [logOut] = useTypedMutation(
    {
      logout: true,
    },
    { onCompleted: () => router.replace("/") }
  );

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <Sider
        theme="light"
        collapsible
        collapsed={collapsed}
        defaultCollapsed={collapsed}
        onCollapse={(c) => setCollapsed(c)}
      >
        <div className={styles.logo}>
          <img
            src="/assets/logo.png"
            style={{
              maxWidth: "100%",
            }}
          />
        </div>
        <Menu
          theme="light"
          defaultSelectedKeys={["/dash"]}
          selectedKeys={[router.pathname]}
          mode="inline"
          style={{ height: "100%" }}
        >
          <Menu.Item key="/dash" icon={<PieChartOutlined />}>
            <Link href="/dash">Performance</Link>
          </Menu.Item>
          <Menu.Item key="/dash/menus" icon={<CoffeeOutlined />}>
            <Link href="/dash/menus">Menus</Link>
          </Menu.Item>
          <Menu.Item key="/dash/times" icon={<ClockCircleOutlined />}>
            <Link href="/dash/times">Opening Times</Link>
          </Menu.Item>
          <Menu.Item key="/dash/settings" icon={<SettingOutlined />}>
            <Link href="/dash/settings">Pickup Settings</Link>
          </Menu.Item>
          <Menu.Item key="/dash/payments" icon={<CreditCardOutlined />}>
            <Link href="/dash/payments">Payments</Link>
          </Menu.Item>
          <Menu.Item key="/dash/live" icon={<AlertOutlined />}>
            <Link href="/dash/live">Live Orders</Link>
          </Menu.Item>
          <Menu.Item key="/dash/order-history" icon={<ShopOutlined />}>
            <Link href="/dash/order-history">Order History</Link>
          </Menu.Item>
          <Menu.Item key="/dash/reusables" icon={<RestOutlined />}>
            <Link href="/dash/reusables">Reusables</Link>
          </Menu.Item>
          <Menu.Item key="/dash/tablet" icon={<TabletOutlined />}>
            <Link href="/dash/tablet">Tablets</Link>
          </Menu.Item>
          <Menu.Item key="/dash/sites" icon={<CloudOutlined />}>
            <Link href="/dash/sites">Sites</Link>
          </Menu.Item>
          <SubMenu
            key="3"
            title={
              <span>
                <UserOutlined />
                <Text>Your Account</Text>
              </span>
            }
          >
            <Menu.Item key="/dash/account" icon={<SettingOutlined />}>
              <Link href="/dash/account">Settings</Link>
            </Menu.Item>
            <Menu.Item key="4" danger onClick={logOut.bind(null)}>
              Log out
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="dark-mode" icon={<DarkModeToggle />}></Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, height: 84, backgroundColor: "white" }}>
          <HeaderBar />
        </Header>
        <Content>
          <div
            style={{
              padding: 8,
              backgroundColor: "white",
              minHeight: "calc(100vh - 84px)",
            }}
          >
            {children}
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }}>ecoeats</Footer>
      </Layout>
    </Layout>
  );
}

const styles = {
  logo: style(
    media(
      {
        minWidth: "1000px",
      },
      {
        margin: 8,
        display: "none",
      }
    )
  ),
};
