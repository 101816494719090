import { PriceStrategy } from '@/graphql/graphql-zeus';
import { ModifierGroupOption, OptionsStrategy } from '@/pages/dash/menus/modifier-group/[id]';
import React from 'react';
import { ModifierOption } from './menu';

export interface ModifierGroupFormVal {
  name?: string;
  instruction?: string;
  priceStrategy?: PriceStrategy;
  options: ModifierGroupOption[];
  optionsStrategy?: OptionsStrategy;
  maxPerOption?: number | null;
  minOptions?: number | null;
  maxOptions?: number | null;
  defaultMaxOptions: boolean;
}

interface CreateModifierGroupContextVal {
  value: ModifierGroupFormVal | null;
  updateModifierGroupFormValues: (value: Partial<ModifierGroupFormVal> | null) => void;
}

const CreateModifierGroupContext = 
  React.createContext<CreateModifierGroupContextVal>({
    value: null,
    updateModifierGroupFormValues: () =>  {}
  });

export default CreateModifierGroupContext;