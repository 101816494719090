import { useStore } from "@/store/store";
import CreateModifierGroupContext, { ModifierGroupFormVal } from "@/utils/CreateModifierGroupContext";
import { CloseOutlined } from "@ant-design/icons";
import { ApolloProvider } from "@apollo/client";
import { RewriteFrames } from "@sentry/integrations";
import * as Sentry from "@sentry/node";
import { Integrations } from "@sentry/tracing";
import { Button, message, Modal } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { DefaultSeo } from "next-seo";
import getConfig from "next/config";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import DashLayout from "../components/DashLayout";
import { useTypedMutation } from "../graphql/hooks";
import { useMe } from "../graphql/queries";
import { useApollo } from "../hooks/useApollo";
import "../styles/antd.less";
import "../utils/hydrate";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, "app:///_next");
          return frame;
        },
      }),
      ...(process.browser ? [new Integrations.BrowserTracing()] : []),
    ],
    tracesSampleRate: 1.0,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const LoggedInArea = ({ children }) => {
  const { error, me } = useMe();
  const router = useRouter();
  const [show, setShow] = useState(false);

  const [logOut] = useTypedMutation(
    {
      logout: true,
    },
    { onCompleted: () => router.replace("/") }
  );

  useEffect(() => {
    if (router.pathname.startsWith("/dash")) {
      if (error) {
        message.warn("Sorry, please log in again.");
        router.push("/");
      } else if (me?.restaurants.length === 0) {
        console.log("fuck");
        setShow(true);
      }
    }
  }, [router, error, me]);

  return (
    <>
      {children}
      <Modal
        visible={show}
        title="You don't own any stores."
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{
          hidden: true,
        }}
        closable={false}
      >
        <Paragraph>
          This portal is for ecoeats Partners and your account doesn't seem to
          own any stores. If this is the account you use to sign into your
          tablet, please log out and use your owner account.
        </Paragraph>
        <Paragraph>
          If you do not know what your owner account is, email{" "}
          <a href="mailto:partner.support@ecoeats.uk">
            partner.support@ecoeats.uk
          </a>{" "}
          and we'll help you out.
        </Paragraph>
        <Button danger onClick={logOut.bind(null)} icon={<CloseOutlined />}>
          Log Out
        </Button>
      </Modal>
    </>
  );
};

function App({ Component, pageProps }) {
  const client = useApollo(pageProps);
  const router = useRouter();
  const initialPartnerIdParam = router.query?.partnerId;
  var initialPartnerId: string | undefined;
  if (Array.isArray(initialPartnerIdParam)) {
    initialPartnerId = initialPartnerId.length > 0 ? initialPartnerId[0] : undefined;
  } else {
    initialPartnerId = initialPartnerIdParam;
  }
  const [setSelectedRestaurantId] = useStore((store) => [
    store.setSelectedRestaurantId,
  ]);
  useEffect(() => {
    if (initialPartnerId) {
      setSelectedRestaurantId(initialPartnerId);
    }
  }, [initialPartnerId]);
  // used to allow the creation of modifier items
  // on a different page while creating a modifier group.
  const [
    modifierGroupFormVal,
    setModifierGroupFormValues,
  ] = useState<ModifierGroupFormVal | null>(null);

  const updateModifierGroupFormValues = useCallback(
    async (val: Partial<ModifierGroupFormVal> | null) => {
      setModifierGroupFormValues((prevVal) => {
        if (val) {
          if (prevVal) {
            return {
              ...prevVal,
              ...val,
            };
          } else {
            const defaultVal = {
              options: [],
              defaultMaxOptions: true,
            };
            return {
              ...defaultVal,
              ...val,
            };
          }
        } else {
          return null;
        }
      });
    },
    [setModifierGroupFormValues]
  );

  return (
    <>
      <DefaultSeo title="ecoeats | zero-emissions delivery" />
      <ApolloProvider client={client}>
        {router.pathname.startsWith("/dash") ? (
          <LoggedInArea>
            <CreateModifierGroupContext.Provider
              value={{
                value: modifierGroupFormVal,
                updateModifierGroupFormValues: async (val) =>
                  await updateModifierGroupFormValues(val),
              }}
            >
              <DashLayout>
                <Component {...pageProps} />
              </DashLayout>
            </CreateModifierGroupContext.Provider>
          </LoggedInArea>
        ) : (
          <Component {...pageProps} />
        )}
      </ApolloProvider>

      <style jsx global>
        {`
          html,
          body {
            padding: 0;
            margin: 0;
            font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
              Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
              sans-serif;
            min-width: fit-content;
          }

          * {
            box-sizing: border-box;
          }

          /* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

          /* total width */
          ::-webkit-scrollbar {
            background-color: #fff;
            width: 14px;
          }

          /* background of the scrollbar except button or resizer */
          ::-webkit-scrollbar-track {
            background-color: none;
          }

          /* scrollbar itself */
          ::-webkit-scrollbar-thumb {
            background-color: #babac0;
            border-radius: 16px;
            border: 4px solid #fff;
          }

          /* set button(top and bottom of the scrollbar) */
          ::-webkit-scrollbar-button {
            display: none;
          }

          tr.drop-over-downward td {
            border-bottom: 2px dashed #31b668;
          }

          tr.drop-over-upward td {
            border-top: 2px dashed #31b668;
          }
        `}
      </style>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
      />
    </>
  );
}

export default App;
