import { IS_STAGING } from "./env";

export const IMAGE_PROXY_URL =
  IS_STAGING || process.env.NODE_ENV === "development"
    ? `https://i.staging.ecoeats.uk`
    : `https://i.ecoeats.uk`;

export const IMAGE_RESTAURANT_BACKGROUND =
  "assets/restaurants/default-background.jpg";

export const getImageUrl = (
  key: string,
  width?: number,
  height?: number,
  mode: "fill" | "fit" = "fill"
) =>
  `${IMAGE_PROXY_URL}/i/${
    width && height
      ? `resize:${mode}:${width}:${height}:0/`
      : width
      ? `resize:${mode}:${width}:0/`
      : ""
  }gravity:ce/plain/${key}`;
