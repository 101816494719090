import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  HttpOptions,
  InMemoryCache,
  split,
} from "@apollo/client";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { createUploadLink as UploadLink } from "apollo-upload-client";
import { extractFiles } from "extract-files";
import { useMemo } from "react";

let apolloClient;

const API_URL =
  process.env.NODE_ENV === "development"
    ? "https://local-api.ecoeats.uk/graphql" //"https://api.staging.ecoeats.uk/graphql"
    : (process as any).browser && location?.hostname === "partner.ecoeats.uk"
    ? "https://api.ecoeats.uk/graphql"
    : "https://api.staging.ecoeats.uk/graphql";

const options: HttpOptions = {
  uri: API_URL,
  credentials: "include",
  headers: {
    "x-ecoeats-prefer-user-type": "restaurant",
  },
};

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.from([
      createPersistedQueryLink() as any,
      split(
        (operation) => extractFiles(operation).files.size > 0,
        new UploadLink({
          ...options,
        }),
        new HttpLink({
          ...options,
        })
      ),
    ]),
    cache: new InMemoryCache(),
    name: "restaurant-dash",
    version: "beta",
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState });
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function useApollo(initialState) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
