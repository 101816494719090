import theme from "@/styles/theme";
import { getImageUrl, IMAGE_RESTAURANT_BACKGROUND } from "@/utils/image-url";
import { BookFilled, PlayCircleFilled } from "@ant-design/icons";
import { Select } from "antd";
import Text from "antd/lib/typography/Text";
import React, { useEffect } from "react";
import { media, style } from "typestyle";
import { useMe } from "../graphql/queries";
import { useStore } from "../store/store";

const tabs: { title: string; key: string; icon: any }[] = [
  {
    title: "New Order",
    key: "/dash",
    icon: <PlayCircleFilled />,
  },
  {
    title: "History",
    key: "/dash/history",
    icon: <BookFilled />,
  },
];

const styles = {
  header: style({
    height: "100%",
    padding: 8,
    width: "100%",
    position: "sticky",
    display: "flex",
    backgroundColor: theme.colors.white,
    flexDirection: "row",
    alignItems: "center",
    top: 0,
    left: 0,
    zIndex: 999,
  }),
  selector: style({
    $nest: {
      ".ant-select-selector": {
        height: "100%!important",
      },
    },
  }),
  logo: style(
    media(
      {
        maxWidth: "1000px",
      },
      {
        display: "none",
      }
    )
  ),
};

const RestaurantOption = ({
  line1,
  name,
  headerImageKey,
}: {
  headerImageKey?: string;
  name: string;
  line1: string;
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }}
    className="restaurant-option"
  >
    <img
      src={getImageUrl(headerImageKey || IMAGE_RESTAURANT_BACKGROUND, 256, 256)}
      style={{
        height: 56,
        width: 56,
        borderRadius: 4,
        marginRight: 8,
      }}
    />
    <div>
      <Text
        className="restaurant-option-name"
        style={{
          display: "block",
        }}
      >
        {name}
      </Text>
      <Text type="secondary">{line1}</Text>
    </div>
  </div>
);

export default () => {
  const { me } = useMe();
  const [selectedRestaurantId, setSelected] = useStore((store) => [
    store.selectedRestaurantId,
    store.setSelectedRestaurantId,
  ]);

  useEffect(() => {
    if (!selectedRestaurantId && me?.restaurants[0])
      setSelected(me.restaurants[0].id);
  }, [selectedRestaurantId, setSelected, me]);

  return (
    <div className={styles.header}>
      <div style={{ flex: 1 }} className={styles.logo}>
        <img
          src="/assets/logo.png"
          id="main-logo"
          style={{
            maxWidth: "192px",
            paddingLeft: 20,
          }}
        />
      </div>
      <Select
        style={{
          width: 240,
          height: "100%",
        }}
        className={styles.selector}
        bordered={false}
        value={me ? selectedRestaurantId : "loading"}
        onChange={(v) => setSelected(v.toString())}
      >
        {me ? (
          me?.restaurants.map((r) => (
            <Select.Option value={r.id} key={r.id} label={r.name}>
              <RestaurantOption
                line1={r.address.address_line_1}
                name={r.name}
                headerImageKey={r.headerImageKey}
              />
            </Select.Option>
          ))
        ) : (
          <Select.Option value={"loading"} label={"loading"}>
            <RestaurantOption line1={"Loading..."} name="Loading..." />
          </Select.Option>
        )}
      </Select>
    </div>
  );
};
